import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./header.css";

function Header() {
  const [left, setLeft] = useState(5);
  const [width, setWidth] = useState(70);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setLeft(5);
      setWidth(70);
    }
    if (location.pathname === "/home") {
      setLeft(5);
      setWidth(70);
    }
    if (location.pathname === "/about") {
      setLeft(80);
      setWidth(58);
    }
    if (location.pathname === "/projects") {
      setLeft(155);
      setWidth(78);
    }
    if (location.pathname === "/tools") {
      setLeft(250);
      setWidth(58);
    }
  }, []);

  return (
    <header className="header">
      <NavLink to="/">
        <h2 className="logo">otabek</h2>
      </NavLink>
      <nav className="nav">
        <div
          className="nav-indicator"
          style={{
            left: `${left}px`,
            width: `${width}px`,
          }}
        ></div>
        <NavLink
          to="/home"
          onClick={(e) => {
            setLeft(5);
            setWidth(e.target.offsetWidth * 1);
          }}
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          onClick={(e) => {
            setLeft(e.target.offsetLeft - 1);
            setWidth(e.target.offsetWidth);
          }}
        >
          About
        </NavLink>
        <NavLink
          to="/projects"
          onClick={(e) => {
            setLeft(e.target.offsetLeft - 1);
            setWidth(e.target.offsetWidth);
          }}
        >
          Projects
        </NavLink>
        <NavLink
          to="/tools"
          onClick={(e) => {
            setLeft(e.target.offsetLeft);
            setWidth(e.target.offsetWidth);
          }}
        >
          Tools
        </NavLink>
      </nav>
      <a className="nav-contact" href="mailto:sharipovsdev@gmail.com">
        Contact
      </a>
    </header>
  );
}

export default Header;
